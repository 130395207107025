var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('CRow',[(!_vm.fgUnconsolidated)?_c('CCol',{staticClass:"d-flex justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.house'),
          placement: 'top-end'
        }),expression:"{\n          content: $t('label.house'),\n          placement: 'top-end'\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleAdd}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1):_vm._e(),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedItems,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: `${_vm.$t('label.see')} ${_vm.$t('label.house')}`,
              placement: 'top-start'
            }),expression:"{\n              content: `${$t('label.see')} ${$t('label.house')}`,\n              placement: 'top-start'\n            }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.getHouse(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}}])})],1)],1),_c('ModalBl',{attrs:{"modal":_vm.ModalBl,"Bl":_vm.itemEdit,"BlMaster":_vm.Bl,"items":_vm.Bl?.HouseJson ?? [],"title":_vm.titleModal,"isEdit":_vm.isEdit,"fgHouse":true},on:{"update:modal":function($event){_vm.ModalBl=$event},"close":_vm.closeModal,"Update-list":_vm.Update}}),_c('ModalBlDocument',{attrs:{"modal":_vm.ModalBlDocument,"title":_vm.titleDocumentsModal,"documentList":_vm.documentList},on:{"update:modal":function($event){_vm.ModalBlDocument=$event},"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }