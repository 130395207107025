import { required, maxLength } from 'vuelidate/lib/validators';
import { onlyAlphanumeric5, validationSeal } from '@/_validations/validacionEspeciales'
import { DateFormater } from "@/_helpers/funciones";

export default (PreviousDate, LaterDate, DispatchDate, EventService, fromDay, toDay, misTime) => {
    let EventServiceValid = EventService ? { required } : true;
    let EventReason =  { required };

    let from = misTime ?
    { required, TimeValidate(value){
        return(DateFormater.formatDateTimeWithoutSlash(value) >= PreviousDate &&
            DateFormater.formatDateTimeWithoutSlash(value) <= LaterDate && 
            DateFormater.formatDateTimeWithoutSlash(value) < DispatchDate 
        )
    },
    RangeDate(value){
        return toDay ? DateFormater.formatDateTimeWithoutSlash(value) <  DateFormater.formatDateTimeWithoutSlash(toDay) : true;
    } } : true;

    return {
        YardName: {},
        EventReasonId: EventReason,
        EventServiceId: EventServiceValid,
        MachineId: {},
        TransactionDate: {},
        fromDay: from,
        toDay: {},
        Observation: { onlyAlphanumeric5 },
        Seal1: {},
        Seal2: {},
        Seal3: {},
        Seal4: {},
    }
}