<template>
  <div class="mt-2">
    <CRow>
      <CCol v-if="!fgUnconsolidated" sm="12" class="d-flex justify-content-end">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="toggleAdd"
          v-c-tooltip="{
            content: $t('label.house'),
            placement: 'top-end'
          }"
        >
          <CIcon name="cil-playlist-add"/>&nbsp;
          {{$t('label.nuevo')}}
        </CButton>
      </CCol>
      <CCol col="12" xl="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
          <template #Status="{ item }">
            <td class="cell-center text-center">
              <CBadge :color="getBadge(item.Status)">
                {{ $t('label.'+item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Detalle="{item}">
            <td class="text-center">
            <CButton
              square
              size="sm"
              color="watch"
              v-c-tooltip="{
                content: `${$t('label.see')} ${$t('label.house')}`,
                placement: 'top-start'
              }"
              @click="getHouse(item)"
            >
              <CIcon name="eye" />
            </CButton>
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalBl
      :modal.sync="ModalBl"
      :Bl="itemEdit"
      :BlMaster="Bl"
      :items="Bl?.HouseJson ?? []"
      :title="titleModal"
      :isEdit="isEdit"
      :fgHouse="true"
      @close="closeModal"
      @Update-list="Update"
    />
    <ModalBlDocument
      :modal.sync="ModalBlDocument"
      :title="titleDocumentsModal"
      :documentList="documentList"
      @close="closeModal"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import ModalBl from '../basicData/modal-bl/index';
import ModalBlDocument from '@/pages/yard-management/container/bl/modal-bl-document';
import { DateFormater, trimRemoveSpecialCharacters} from '@/_helpers/funciones';
import { formatMilDecimal } from '@/_validations/validacionEspeciales';

//DATA-------------------------
function data() {
  return {
    ModalBl: false,
    ModalBlDocument: false,
    itemEdit:{},
    titleModal: '',
    titleDocumentsModal: '',
    isEdit: false,
    documentList: [],
  }
}
//methods
function toggleAdd() {
  try{
    this.VForm.dataBasic.$touch();
    this.VForm.PackagingList.$touch();
    this.VForm.seller.$touch();
    this.VForm.customBroke.$touch();
    this.VForm.consolidator.$touch();
    this.VForm.consignee.$touch();
    this.VForm.notify.$touch();
    this.VForm.DescriptionOfGoods.$touch();

    if (this.VForm.dataBasic.$error || this.VForm.seller.$error || this.VForm.customBroke.$error || this.VForm.consolidator.$error || this.VForm.consignee.$error || 
      this.VForm.notify.$error || this.VForm.DescriptionOfGoods.$error || this.VForm.PackagingList.$error) {
      throw this.$t("validation.MissingInformationBl");
    }

    this.titleModal = `${this.$t('label.nuevo')} ${this.$t('label.BlHouse')}`;
    this.ModalBl = true;
  }catch (error) {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

function getHouse(item){
  this.$emit('getHouse', item);
}

async function Update() {
  this.$emit('Update', this.Bl);
}

function closeModal(){
  this.ModalBl = false;
  this.ModalBlDocument = false;
  this.documentList = [];
  this.itemEdit = {};
  this.titleModal = '';
  this.titleDocumentsModal = '';
  this.isEdit = false;
}

//computed
function formatedItems() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let list = this.Bl?.HouseJson ?? [];
  return list.map((item) => {
    return {
      ...item,
      Nro: Number.parseInt(item.IdX),
      ComputedBookingNro: item.BookingNro ? item.BookingNro : 'N/A',
      ComputedCustomOperation: item[`CustomOperationDs${_lang}`] ? item[`CustomOperationDs${_lang}`] : 'N/A',
      ComputedCustomBrokerName: item.CustomBrokerName ?? 'N/A',
      ComputedClientName: item.ClientName ?? 'N/A',
      ComputedDua: item.Dua ? item.Dua : 'N/A',
      ComputedProcedence: item.Procedence ? item.Procedence : 'N/A',
      ComputedReceptionBpAct: item.ReceptionBpAct ? item.ReceptionBpAct : 'N/A',
      ComputedQuantity: item.Quantity ? formatMilDecimal(item?.Quantity?.toFixed(2)) : '0,00',
      ComputedWeigth: item?.Weigth ? formatMilDecimal(item?.Weigth?.toFixed(2)) : '0,00',
      ComputedVolumen: item?.Volumen ? formatMilDecimal(item?.Volumen?.toFixed(2)) : '0,00',
      ComputedDescriptionOfGoods: item?.DescriptionOfGoods ? trimRemoveSpecialCharacters(item.DescriptionOfGoods, 50) : 'N/A',
      TransaLogin: item.TransaLogin ?? 'N/A',
      _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
      FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
    };
  });
}

function fields(){
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return [
    { key: "Detalle", label: "",_style:'min-width:45px; max-width:45px', _classes:'center-cell',filter: false,},
    { key: 'Nro', label: '#',_style: 'min-width: 60px; text-align:left;', filter: false, _classes: 'text-uppercase text-center'},
    { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'ComputedClientName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedCustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedCustomOperation', label: this.$t('label.customsRegime'),  _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedBookingNro', label: this.$t('label.nBooking'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;', sorter: true, filter: true},
    { key: 'ComputedDescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: `BlStatus${_lang}`, label: this.$t('label.blStatus'), _style:'min-width:200px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedDua', label: this.$t('label.nDua'), _style:'min-width:140px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedReceptionBpAct', label: this.$t('label.bolipuertoReceptionRecordNro'), _style:'min-width:300px; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'ComputedProcedence', label: this.$t('label.Origin'),  _style:'min-width:150px; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
    { key: 'ComputedWeigth', label: this.$t('label.weight')+' '+('(KGM)'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'ComputedVolumen', label: this.$t('label.volume')+' '+('(M³)'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center' },
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'FormatedDate', label: this.$t('label.date'), _style:'min-width: 180px;', _classes:"text-center"},
    { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
  ];
}

export default {
  name: 'basicData-index',
  components: {
    ModalBl,
    ModalBlDocument,
  },
  data,
  mixins: [GeneralMixin],
  props: {
    Bl: {
      type: Object,
      Required: true,
      default: undefined,
    },
    VForm: {
      type: Object,
      Required: true,
      default: undefined,
    },
    fgUnconsolidated: Boolean,
  },
  methods: {
    toggleAdd,
    getHouse,
    Update,
    closeModal,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      YardId: state => state.yardManagement.yardData.YardId,
      dataContainer: state => state.yardManagement.dataContainer,
      GeneralList: state => state.yardManagement.GeneralList,
      yardCollapse: state => state.yardManagement.yardCollapse,
      isSelect: state => state.yardManagement.isSelect,
      tabIndex: state => state.yardManagement.ContainerTabIndex,
      FgPositioning: state => state.yardManagement.FgPositioning,
    })
  },
};
</script>