<template>
    <div>
        <CCollapse :show="activeItem(1)">
            <BlReception :fgCertificate="fgCertificate"/>
        </CCollapse>
        <CCollapse :show="activeItem(2)">
            <BlPositioning/>
        </CCollapse>
        <CCollapse :show="activeItem(3)">
            <Dispatch/>
        </CCollapse>
        <CCollapse :show="activeItem(4)">
            <Desconsolidated/>
        </CCollapse>
        <CCollapse :show="activeItem(5)">
            <House/>
        </CCollapse>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import BlReception from './reception/bl-reception-index';
    import BlPositioning from './positioning/bl-positioning-index';
    import Dispatch from './dispatch/bl-dispatch-index';
    import Desconsolidated from './events/desconsolidated/desconsolidated-index';
    import House from './events/pendingHouse/housesList';
    
    //methods
    function activeItem(item) {
        return this.dropBlMovement === item;
    }
    export default {
        name:"movements-index",
        props: {
            fgCertificate: Boolean,
        },
        methods:{
            activeItem,
        },
        components:{
            BlReception,
            BlPositioning,
            Desconsolidated,
            House,
            Dispatch
        },
        computed:{
            ...mapState({
                tabIndex: state => state.yard-management.tabMovementIndex,
                dropBlMovement: state => state.yardManagement.dropBlMovement,
            })
        },
        watch:{
            //tabIndex
        }
    }
</script>