<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol v-if="!fgInfo" sm="12">
      <CTabs :active-tab="TabIndex" @update:activeTab="TabIndex=$event" variant="tabs" class="m-0">
        <CTab :title="$t('label.generalInfo')" :class="vForm.Packaging.PackagingId.$error || vForm.Packaging.Quantity.$error || vForm.Packaging.Weight.$error ? 'tab-error' : ''">
          <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
            <CRow class="m-0">
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-2">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.PackagingOrigin')"
                  :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                  :options="PackagingOriginOptions"
                  addLabelClasses="required text-right"
                  v-model="vForm.Packaging.PackagingOrigin.$model"
                  :is-valid="hasError(vForm.Packaging.PackagingOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.PackagingOrigin)"
                  @change="onChangePackagingOrigin($event.target.value)"
                />
              </CCol>
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-2">
                <CSelect
                  v-if="SerialJson.length != 0"
                  size="sm"
                  v-uppercase
                  :label="$t('label.SerialOrigin')"
                  :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                  :options="SerialOriginOptions"
                  :addLabelClasses="form.Packaging.FgSerialOrigin ? 'required text-right' : 'text-right'"
                  v-model="vForm.Packaging.SerialOrigin.$model"
                  :is-valid="hasError(vForm.Packaging.SerialOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.SerialOrigin)"
                  @change="onChangeSerialOrigin($event.target.value)"
                />
                <CInput
                  v-else
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.SerialOrigin')"
                  addLabelClasses="text-right"
                  :label="$t('label.SerialOrigin')"
                  :horizontal="{label: 'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                  value="N/A"
                  :is-valid="hasError(vForm.Packaging.SerialOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.SerialOrigin)"
                  disabled
                />
              </CCol>
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 required col-form-label-sm">{{`${$t('label.WeightOrigin')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.WeightOrigin.$dirty ? 'form-control' : (vForm.Packaging.WeightOrigin.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.WeightOrigin.$model"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.WeightOrigin.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.WeightOrigin) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol  sm="12" lg="4" class="px-2">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.commodity')"
                  :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                  :options="CommodityOptions"
                  addLabelClasses="text-right"
                  v-model="vForm.Packaging.CommodityId.$model"
                  :is-valid="hasError(vForm.Packaging.CommodityId)"
                  :invalid-feedback="errorMessage(vForm.Packaging.CommodityId)"
                  @change="onChangeCommodityId"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <CInput
                  v-uppercase
                  size="sm"
                  placeholder="RUBRO"
                  addLabelClasses="text-right"
                  label="RUBRO"
                  :horizontal="{label: 'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                  v-model="vForm.Packaging.HeadingName.$model"
                  :is-valid="hasError(vForm.Packaging.HeadingName)"
                  :invalid-feedback="errorMessage(vForm.Packaging.HeadingName)"
                  disabled
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <CInput
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.serial')"
                  addLabelClasses="text-right"
                  :label="$t('label.serial')"
                  :horizontal="{label: 'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                  v-model="vForm.Packaging.Serial.$model"
                  :is-valid="hasError(vForm.Packaging.Serial)"
                  :invalid-feedback="errorMessage(vForm.Packaging.Serial)"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!vForm.Packaging.Quantity.$dirty ? 'form-control' : (vForm.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Quantity.$model"
                      maxlength= "10"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Quantity.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Quantity) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 required col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Weight.$dirty ? 'form-control' : (vForm.Packaging.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Weight.$model"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Weight.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Weight) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-6 m-0 text-right">
                    {{$t('label.ApplyPackaging')}}
                  </label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="form.Packaging.FgPackaging"
                      @update:checked="(vForm.Packaging.PackagingId.$model = '', FgExternPackaging = false)"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2" v-if="form.Packaging.FgPackaging">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.packaging')"
                  :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                  :options="PackagingOptions"
                  addLabelClasses="required text-right"
                  v-model="vForm.Packaging.PackagingId.$model"
                  :is-valid="hasError(vForm.Packaging.PackagingId)"
                  :invalid-feedback="errorMessage(vForm.Packaging.PackagingId)"
                  @change="onChangePackagingId($event.target.value)"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-2" v-if="form.Packaging.FgPackaging">
                <div class="d-flex justify-content-end">
                  <div class="col-sm-auto col-lg-12 d-flex p-0">
                    <label class="col-form-label-sm col-sm-auto col-lg-6 text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.internal')}}
                    </label>
                    <div class="col-sm-auto d-flex justify-content-end" style="padding-right: 5px; padding-left: 5px;">
                      <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="FgExternPackaging"
                      />
                    </div>
                    <label class="col-form-label-sm col-sm-auto text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.external')}}
                    </label>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2" v-if="form.Packaging.FgPackaging">
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-6 m-0 text-right">
                    {{$t('label.ApplySerializatiom')}}
                  </label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="form.Packaging.FgRequiredSerialization"
                      :disabled="form.Packaging.PackagingId ? !PackagingData?.FgApplySerialization : true"
                      @update:checked="updateFgRequiredSerialization()"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <CTextarea
                  :label="$t('label.description')"
                  :horizontal="{label:'col-sm-12 col-lg-6', input:'col-sm-12 col-lg-6'}"
                  size="sm"
                  v-uppercase
                  v-model.trim="vForm.Packaging.DescriptionOfGoods.$model"
                  addLabelClasses="text-right"
                  rows="2"
                  :is-valid="hasError(vForm.Packaging.DescriptionOfGoods)"
                  :invalid-feedback="errorMessage(vForm.Packaging.DescriptionOfGoods)"
                />
              </CCol>
            </CRow>
          </div>
        </CTab>
        <CTab :title="$t('label.dimensions')" :class="vForm.Packaging.Length.$error || vForm.Packaging.Width.$error || vForm.Packaging.Height.$error ? 'tab-error' : ''">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <CRow class="m-0">
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Length.$dirty ? 'form-control' : (vForm.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Length.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Length.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Length) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Width.$dirty ? 'form-control' : (vForm.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Width.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Width.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Width) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Height.$dirty ? 'form-control' : (vForm.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Height.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Height.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Height) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 col-form-label-sm">{{`M²`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!vForm.Packaging.Length.$dirty || !vForm.Packaging.Width.$dirty || !vForm.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.SquareMeters"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 m-0 col-form-label-sm">{{`${$t('label.volume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!vForm.Packaging.Length.$dirty || !vForm.Packaging.Width.$dirty || !vForm.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.Volumen"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </CTab>
        <CTab v-if="!form.Packaging.FgRequiredSerialization" :title="$t('label.imdg')" :disabled="form.Packaging.Quantity == 0 || form.Packaging.Weight == 0">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <ImdgTab
              :form="form"
              :imdgItems="imdgItems"
              :Tab="Tab"
              :fgPackagingTab="true"
              :Clean="CleanInformation"
              @deleteImdg="(event) => deleteImdg(event)"
              @imdgUpdateItems="(event)=>{submitImdg(event)} "
            />
          </div>
        </CTab>
      </CTabs>
      <CCol sm="12 px-0">
        <div class="col-sm-12 col-form-label-sm text-right px-0" style="text-align: left;">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="SubmitPackaging()"
          >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            @click="Clear(false)"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CCol>
           
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListPackaging"
        :fields="packagingfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #WeigthParent="{ item }">
          <td class="text-center">
            {{ formatNumber(item.WeigthParent, 2) }}
          </td>
        </template>
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #QuantityReceived="{ item }">
          <td class="text-center">
            {{ formatNumber(item.QuantityReceived, 2) }}
          </td>
        </template>
        <template #Weight="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Weight, 2) }}
          </td>
        </template>
        <template #Volumen="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Volumen, 2) }}
          </td>
        </template>
        <template #SquareMeters="{ item }">
          <td class="text-center">
            {{ formatNumber(item.SquareMeters, 2) }}
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              v-if="!fgInfo"
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end'
              }"
              @click="EditPackaging(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              v-if="!fgInfo"
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="text-invalid-feedback text-center" v-if="vForm.PackagingList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import ImdgTab from '@/pages/yard-management/container/bl/tabs/imdg-tab';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    TabIndex: 0,
    FgExternPackaging: false,
    CleanInformation: false,
    isSubmit: false,
    PackagingSelectList: [],
    PackagingData: {},
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    imdgItems: [],
    DetailJson: [],
    SerialJson: [],
  };
}

//methods
function getService() {
  this.$store.state.yardManagement.loading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "Packaging-list", { Filter: 'ACTIVO' }),
  ];
  if (this.validCargoMaster) {
    peticiones.push(this.$http.ejecutar("GET", "YardWBlCargoPackagingInYard-list", { BlCargoId: this.form.dataBasic.BlCargoMasterId }))
  }
  Promise.all(peticiones)
    .then((responses) => {
      this.PackagingSelectList = responses[0].data.data ?? [];
      if (this.validCargoMaster) {
        this.DetailJson = responses[1].data.data ?? [];
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function SubmitPackaging() {
  try {
    this.isSubmit = true;
    this.vForm.Packaging.$touch();
    if (this.vForm.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    let ImdgActivesList = this.imdgItems.filter(e => e.Status == 1);

    if (
      this.form.PackagingList.some(e => {
        let ImdgActiveJson = e.ImdgJson.filter(el => el.Status == 1)
        return e.Status == 1 && (this.form.Packaging.Id=='' ? true : e.IdX != this.form.Packaging.Id) && e.PackagingId == this.form.Packaging.PackagingId && 
          (ImdgActiveJson.length == 0 ? 
            ImdgActivesList.length == 0 :
            ImdgActiveJson.some(el => ImdgActivesList.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)))
      })
    ) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    if (this.form.Packaging.Id && this.PackagingData?.FgApplySerialization && this.form.SerializationList.length != 0) {
      let accumulator = { Weight: 0, Volumen: 0, Quantity: 0 };
      this.form.SerializationList.map(item => {
        if (item?.Status != 0 && item.PackagingId == this.form.Packaging.PackagingId) {
          accumulator.Weight += item.Weight;
          accumulator.Volumen += item.Volumen;
          accumulator.Quantity += 1;
        }
      })
      if (NumberFormater.setNum(this.form.Packaging.Quantity) < accumulator.Quantity) {
        throw this.$t('label.NotSerializedQuantity');
      }
      if (NumberFormater.setNum(this.form.Packaging.Weight) < accumulator.Weight) {
        throw this.$t('label.NotSerializedWeight');
      }
      if (NumberFormater.setNum(this.form.Packaging.Volumen) < accumulator.Volumen) {
        throw this.$t('label.NotSerializedVolume');
      }
    }

    let PackagingParentData = {};
    if (this.validCargoMaster) {
      PackagingParentData = this.DetailJson.find(e => e.BlCargoMasterDetailId == this.form.Packaging.PackagingOrigin);
    }

    if (this.form.Packaging.Id=='') {
      this.form.PackagingList.push({
        IdX: this.form.PackagingList.length > 0 ? this.form.PackagingList[this.form.PackagingList.length - 1].IdX + 1 : 1,
        BlCargoMasterDetailParentId: this.validCargoMaster ? this.form.Packaging.PackagingOrigin : '',
        BlCargoMasterDetailSerialParentId: this.validCargoMaster ? this.form.Packaging.SerialOrigin : '',
        PackagingParentNameEn: PackagingParentData?.PackagingNameEn ?? '',
        PackagingParentNameEs: PackagingParentData?.PackagingNameEs ?? '',
        SerialParent: this.SerialJson.length != 0 ? PackagingParentData?.SerialJson?.find(e => e.BlCargoMasterDetailSerialId == this.form.Packaging.SerialOrigin)?.Serial : '',
        WeigthParent: this.validCargoMaster ? NumberFormater.setNum(this.form.Packaging.WeightOrigin) : 0,
        PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
        HeadingId: this.form.Packaging.HeadingId ?? '',
        HeadingName: this.form.Packaging.HeadingName ?? '',
        CommodityId: this.form.Packaging.CommodityId ?? '',
        CommodityName: this.form.Packaging.CommodityName ?? '',
        Serial: this.form.Packaging.Serial ?? '',
        DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',  
        Length: NumberFormater.setNum(this.form.Packaging.Length),
        Width: NumberFormater.setNum(this.form.Packaging.Width),
        Height: NumberFormater.setNum(this.form.Packaging.Height),
        SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
        Weight: NumberFormater.setNum(this.form.Packaging.Weight),
        Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
        Volumen: NumberFormater.setNum(this.Meters.Volumen),
        PackagingNameEn: this.PackagingData?.PackagingNameEn ?? '',
        PackagingNameEs: this.PackagingData?.PackagingNameEs ?? '',
        FgExternPackaging: this.FgExternPackaging,
        FgApplySerialization: this.PackagingData?.FgApplySerialization ? true : false,
        FgRequiredSerialization: this.form.Packaging?.FgRequiredSerialization ? true : false,
        ImdgJson: this.imdgItems,
        Status: 1,
      });
    }else{
      this.form.PackagingList = this.form.PackagingList.map(item => {
        if (item.IdX == this.form.Packaging.Id) {
          return {
            ...item,
            IdX: item.IdX,
            BlCargoMasterDetailId: item.BlCargoMasterDetailId,
            BlCargoMasterDetailParentId: this.validCargoMaster ? this.form.Packaging.PackagingOrigin : '',
            BlCargoMasterDetailSerialParentId: this.validCargoMaster ? this.form.Packaging.SerialOrigin : '',
            PackagingParentNameEn: PackagingParentData?.PackagingNameEn ?? '',
            PackagingParentNameEs: PackagingParentData?.PackagingNameEs ?? '',
            SerialParent: this.form.Packaging.SerialOrigin ?? '',
            WeigthParent: this.validCargoMaster ? NumberFormater.setNum(this.form.Packaging.WeightOrigin) : 0,
            HeadingId: this.form.Packaging.HeadingId ?? '',
            HeadingName: this.form.Packaging.HeadingName ?? '',
            CommodityId: this.form.Packaging.CommodityId ?? '',
            CommodityName: this.form.Packaging.CommodityName ?? '',
            Serial: this.form.Packaging.Serial ?? '',
            DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',
            Length: NumberFormater.setNum(this.form.Packaging.Length),
            Width: NumberFormater.setNum(this.form.Packaging.Width),
            Height: NumberFormater.setNum(this.form.Packaging.Height),
            SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
            PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
            Weight: NumberFormater.setNum(this.form.Packaging.Weight), 
            Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
            Volumen: NumberFormater.setNum(this.Meters.Volumen),
            PackagingNameEn: this.PackagingData?.PackagingNameEn ?? '',
            PackagingNameEs: this.PackagingData?.PackagingNameEs ?? '',
            FgExternPackaging: this.FgExternPackaging,
            FgApplySerialization: this.PackagingData?.FgApplySerialization ? true : false,
            FgRequiredSerialization: this.form.Packaging?.FgRequiredSerialization ? true : false,
            ImdgJson: this.imdgItems,
            Status: 1,
          }
        }else{
          return item;
        }
      });
    }
    await this.Clear(false);
    this.isSubmit = false;
    this.vForm.Packaging.$reset();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

async function EditPackaging(item) {
  this.form.Packaging = {
    Id: item.IdX,
    PackagingOrigin: item?.BlCargoMasterDetailParentId ?? '',
    SerialOrigin: item?.BlCargoMasterDetailSerialParentId ?? '',
    WeightOrigin: item?.WeigthParent ?? 0,
    PackagingId: item.PackagingId ?? '',
    PackagingNameEn: item.PackagingNameEn,
    PackagingNameEs: item.PackagingNameEs,
    HeadingId: item.HeadingId ?? '',
    HeadingName: item.HeadingName ?? '',
    CommodityId: item.CommodityId ?? '',
    CommodityName: item.CommodityName ?? '',
    Serial: item.Serial ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: item.Weight ?? 0, 
    Quantity: item.Quantity,
    FgPackaging: item.PackagingId ? true : false,
    FgRequiredSerialization: item.FgRequiredSerialization ? true : false,
    FgSerialOrigin: false,
  };
  this.imdgItems = item?.ImdgJson ?? [];
  this.FgExternPackaging = item.FgExternPackaging ? true : false;
  await this.onChangePackagingId(this.form.Packaging.PackagingId);
  if (this.validCargoMaster) {
    await this.onChangePackagingOrigin(this.form.Packaging.PackagingOrigin);
    if (this.SerialJson.length != 0) {
      await this.onChangeSerialOrigin(item.BlCargoMasterDetailSerialParentId);
    }
  }
  this.vForm.Packaging.$touch();
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.FgApplySerialization && this.form.SerializationList.some(serialization => serialization.Packaging == item.Packaging)) {
          this.notifyError({text: this.$t('label.PackagingIsSerialized')});
        }else{
          if (item.BlCargoMasterDetailId) {
            this.form.PackagingList = this.form.PackagingList.map(Packaging => {
              if (Packaging.BlCargoMasterDetailId == item.BlCargoMasterDetailId) {
                return {
                  ...Packaging,
                  ImdgJson: Packaging?.ImdgJson?.map(e => {
                  return {
                    ...e,
                    Status: 0,
                  }
                }),
                  Status: 0,
                };
              } else {
                return Packaging;
              }
            });
          }else{
            this.form.PackagingList = this.form.PackagingList.filter(e => e.IdX != item.IdX);
          }
          this.vForm.PackagingList.$touch();
        }
      }
    });
}

function onChangePackagingOrigin(id) {
  this.form.Packaging.PackagingOrigin = id;
  this.form.Packaging.SerialOrigin = '';
  if (this.form.Packaging.PackagingOrigin) {
    let PackagingOriginData = this.DetailJson.find(e => e.BlCargoMasterDetailId == this.form.Packaging.PackagingOrigin);
    this.SerialJson = PackagingOriginData?.SerialJson ?? [];
    this.form.Packaging.FgSerialOrigin = this.SerialJson.length != 0;
    this.form.Packaging.WeightOrigin = this.SerialJson.length != 0 ? 0 : (PackagingOriginData?.Weigth ?? 0);
  }else{
    this.form.WeightOrigin = 0;
    this.SerialJson = [];
    this.form.Packaging.FgSerialOrigin = false;
  }
}

function onChangeSerialOrigin(id) {
  this.form.Packaging.SerialOrigin = id;
  this.form.Packaging.WeightOrigin = 0
  if (this.form.Packaging.SerialOrigin) {
    let SerialJsonData = this.SerialJson.find(e => e.BlCargoMasterDetailSerialId == this.form.Packaging.SerialOrigin);
    this.form.Packaging.WeightOrigin = SerialJsonData?.Weigth ?? 0;
  }
}

function onChangeCommodityId(event) {
  this.form.Packaging.CommodityId = event.target.value;
  if (this.form.Packaging.CommodityId) {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let CommodityData = this.CommodityList.find(item => item.CommodityId == this.form.Packaging.CommodityId);
    this.form.Packaging.HeadingName = CommodityData?.[`HeadingName${_lang}`] ?? '';
    this.form.Packaging.HeadingId = CommodityData?.HeadingId ?? '';
    this.form.Packaging.CommodityName = CommodityData?.[`CommodityName${_lang}`] ?? '';
  }else{
    this.form.Packaging.HeadingId = '';
    this.form.Packaging.HeadingName = '',
    this.form.Packaging.CommodityName = '';
  }
}

function updateFgRequiredSerialization() {
  this.imdgItems = this.imdgItems.filter(e => e.BlCargoMasterImdgId).map(e => {
    return {
      ...e,
      Status: 0,
    }
  });
}

function onChangePackagingId(id) {
  this.form.Packaging.PackagingId = id;
  if (this.form.Packaging.PackagingId) {
    this.PackagingData = this.PackagingSelectList.find(item => item.PackagingId == this.form.Packaging.PackagingId) ?? {};
  }else{
    this.PackagingData = {};
  }
}

function submitImdg(item) {
  if (this.form.imdg.Id == 0) 
    this.imdgItems.push(item);
  else
    this.imdgItems = this.imdgItems.map((e) =>{
      if (e.IdX == this.form.imdg.Id) {
        return {
          ...e,
          ...item
        }
      }else {
        return e
      }
    });
}

function deleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      this.imdgItems = this.imdgItems.filter(e => e.IdX != item.IdX);
    }
  });
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function Clear(All) {
  this.CleanInformation = true;
  this.TabIndex = 0;
  this.form.Packaging = {
    Id: '',
    PackagingOrigin: '',
    SerialOrigin: '',
    WeightOrigin: 0,
    PackagingId: '',
    PackagingNameEn: '',
    PackagingNameEs: '',
    HeadingId: '',
    HeadingName: '',
    CommodityId: '',
    CommodityName: '',
    Serial: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    Weight: 0, 
    Quantity: 0,
    Volumen: 0,
    FgPackaging: false,
    FgRequiredSerialization: false,
    FgSerialOrigin: false,
  };
  this.imdgItems = [];
  this.FgExternPackaging = false;
  this.PackagingData = {};
  this.SerialJson = [];
  if (All) {
    this.form.PackagingList = [];
    this.PackagingSelectList = [];
  }
  setTimeout(() => {
    this.vForm.Packaging.$reset();
    this.CleanInformation = false;
  }, 10);
}

//computed
function PackagingOriginOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.DetailJson.map((e) => {
    if (e.PackagingId != process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED) {
      let Imdg = e?.ImdgJson?.[0] ?? {};
      chart.push({
        value: e.BlCargoMasterDetailId,
        label: `${e['PackagingName'+_lang]}, IMO: ${Imdg?.ImdgCode ?? 'N/A'}, ${this.$t('label.group')}: ${Imdg?.PackagingGroupCode ?? 'N/A'}, ${this.$t('label.UnNumber')}: ${Imdg?.UnNumber ?? 'N/A'}`,
      })
    } 
  })
  return chart;
}

function SerialOriginOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.SerialJson.map((e) => {
    let Imdg = e?.ImdgJson?.[0] ?? {};
    chart.push({
      value: e.BlCargoMasterDetailSerialId,
      label: `${e.Serial}, IMO: ${Imdg?.ImdgCode ?? 'N/A'}, ${this.$t('label.PackagingGroup')}: ${Imdg?.ImdgCode ?? 'N/A'}, ${this.$t('label.UnNumber')}: ${Imdg?.UnNumber ?? 'N/A'} `,
    })    
  })
  return chart;
}

function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingSelectList.filter(item => this.validCargoMaster ? item.PackagingId != process.env.VUE_APP_PACKAGING_ID_CONTAINER : true).forEach((e) => {
    chart.push({
      value: e.PackagingId,
      label: e['PackagingName'+_lang],
    })
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function TotalBl() {
  let Total = {
    Weight: 0,
    WeightReceived: this.form.dataBasic.WeightReceived ?? 0,
    WeightDispatch: this.form.dataBasic.WeightDispatch ?? 0,
    WeightCertified: this.form.dataBasic.WeightCertified ?? 0,
    Volumen: 0,
    VolumenReceived: this.form.dataBasic.VolumenReceived ?? 0,
    VolumenDispatch: this.form.dataBasic.VolumenDispatch ?? 0,
    VolumenCertified: this.form.dataBasic.VolumenCertified ?? 0,
    Quantity: 0,
    QuantityReceived: this.form.dataBasic.QuantityReceived ?? 0,
    QuantityDispatched: this.form.dataBasic.QuantityDispatched ?? 0,
    QuantityCertified: this.form.dataBasic.QuantityCertified ?? 0,
  };
  
  this.form.PackagingList.filter(item => item.Status == 1).forEach(item => {
    Total.Weight += item.Weight;
    Total.Volumen += item.Volumen;
    Total.Quantity += item.Quantity;
  })

  return Total;
}

function Meters() {
  return {
    SquareMeters: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width),
    Volumen: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width) * NumberFormater.setNum(this.form.Packaging.Height),
  }
}

function computedListPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.PackagingList.filter(item => item.Status != 0);
  return List.map((item, index) => {
    let imdgData = item?.ImdgJson?.[0] ?? {};
    return {
      ...item,
      Nro: index+1,
      PackagingOriginName: item['PackagingParentName'+_lang],
      ComputedSerialOrigin: item.SerialParent ?? 'N/A',
      PackagingName: item?.['PackagingName'+_lang] ? item?.['PackagingName'+_lang] : this.$t('label.BreakBulk'),
      ComputedSerial: item?.Serial ? item?.Serial : 'N/A',
      YardCargoBlMasterDetailStatusDs: item?.['YardCargoBlMasterDetailStatusDs'+_lang] ?? '',
      ImdgCod: imdgData?.ImdgCode ?? 'N/A',
      PackagingGroupCod: imdgData?.PackagingGroupCode ?? 'N/A',
      UnNumber: imdgData?.UnNumber ?? 'N/A',
    }
  });
}

function packagingfields(){
  if (this.validCargoMaster) {
    return [
      { key: 'options', label: '', _style: 'width: 1%; min-width:90px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _style: 'min-width: 45px;', _classes: 'text-center', filter: false },
      { key: 'PackagingOriginName', label: this.$t('label.PackagingOrigin'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
      { key: 'ComputedSerialOrigin', label: this.$t('label.SerialOrigin'),  _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
      { key: 'WeigthParent', label: `${this.$t('label.WeightOrigin')} (KGM)`, _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
      { key: 'ComputedSerial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
      { key: 'Quantity', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 150px;'},
      { key: 'QuantityReceived', label: this.$t('label.CertifiedReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
      { key: 'Weight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 150px;', _classes: 'text-uppercase text-center'},
      { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 150px;', _classes:"center-cell text-center"},
      { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'min-width: 150px;'},
      { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
      { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
      { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'min-width: 180px;', _classes:"text-center" },
      { key: 'YardCargoBlMasterDetailStatusDs', label: this.$t('label.status'), _classes: 'text-uppercase text-center text-bold', _style: 'min-width: 180px'},
    ]
  }else{
    return [
      { key: 'options', label: '', _style: 'width: 1%; min-width:90px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _style: 'min-width: 45px;', _classes: 'text-center', filter: false },
      { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
      { key: 'ComputedSerial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
      { key: 'Quantity', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
      { key: 'QuantityReceived', label: this.$t('label.CertifiedReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
      { key: 'Weight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
      { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
      { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
      { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
      { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'min-width: 180px;', _classes:"text-center" },
      { key: 'YardCargoBlMasterDetailStatusDs', label: this.$t('label.status'), _classes: 'text-uppercase text-center text-bold', _style: 'min-width: 180px'},
    ]
  }
}

function TpCargoId() {
  return this.form.dataBasic.TpCargoId;
}

export default {
  name: 'packaging-tab',
  components: {
    ImdgTab,
    Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    CommodityList: {
      type: Array,
      default: () => [],
    },
    vForm: Object,
    showModal: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    fgInfo: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    SubmitPackaging,
    EditPackaging,
    DeletePackaging,
    onChangePackagingOrigin,
    onChangeSerialOrigin,
    onChangeCommodityId,
    updateFgRequiredSerialization,
    onChangePackagingId,
    submitImdg,
    deleteImdg,
    formatNumber,
    Clear,
  },
  computed:{
    PackagingOriginOptions,
    SerialOriginOptions,
    CommodityOptions,
    PackagingOptions,
    TotalBl,
    Meters,
    computedListPackaging,
    packagingfields,
    TpCargoId,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
    TpCargoId: function (Newval) {
      this.form.Packaging.HeadingId = '';
      this.form.Packaging.CommodityName = '';
    },
    TotalBl: function (Newval) {
      this.form.dataBasic.Weight = Newval.Weight;
      this.form.dataBasic.Volumen = Newval.Volumen;
      this.form.dataBasic.Quantity = Newval.Quantity;
    }
  }
};
</script>