var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"12"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.deconsolidatedList')}`)+" ")],1)])],1),_c('CRow',{staticClass:"my-3"},[(_vm.fgDesconsolidate)?_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"12"}},[_c('CRow',[_c('CCol',{staticClass:"align-items-center",attrs:{"sm":"12","xl":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.deconsolidated'),
                  placement: 'top-end'
                }),expression:"{\n                  content: $t('label.deconsolidated'),\n                  placement: 'top-end'\n                }"}],staticClass:"d-flex align-items-center ml-auto",attrs:{"shape":"square","color":"add"},on:{"click":function($event){return _vm.toggleAdd({})}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1)],1)],1):_vm._e()],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"number",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(_vm._s(item.Status))])],1)]}},{key:"buttonOptions",fn:function({item, index}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                              content: _vm.$t('label.editReception'),
                              placement: 'top-start'
                            }),expression:"{\n                              content: $t('label.editReception'),\n                              placement: 'top-start'\n                            }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleAdd(item, true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('DesconsolidatedRegister',{attrs:{"Active":_vm.ActiveRegister,"HouseData":_vm.HouseData,"isEdit":_vm.isEdit,"ContainerItem":_vm.ContainerItem},on:{"Close":function($event){_vm.showIndex=0, _vm.ActiveRegister=false, _vm.isEdit=false, _vm.Update()},"Update-list":_vm.Update}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }