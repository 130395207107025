<template>
    <div class="mb-3">
        <div v-show="showIndex==0" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
                </CCol>
            </CRow>
            <div>
                <CRow>
                    <CCol col="12" xl="12">
                        <dataTableExtended
                            class="align-center-row-datatable"
                            hover
                            sorter
                            small
                            details
                            column-filter
                            :items="formatedItems"
                            :fields="fieldsDesconsolidated"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                            pagination
                        >
                            <template #number="{ index }">
                                <td class="text-center">
                                    {{index+1}}
                                </td>
                            </template>
                            <template #Status="{item}">
                            <td class="text-center">
                                <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                            </td>
                            </template>
                            <template #buttonOptions="{item, index}">
                                <td class="text-center">
                                    <CButton
                                        color="watch"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        v-c-tooltip="{
                                            content: $t('label.blsHousePendingReceive'),
                                            placement: 'top-start'
                                        }"
                                        @click="toggleDeconsolidation(item, true)"
                                    >
                                        <CIcon name='cil-list' />
                                    </CButton>
                                </td>
                            </template>
                        </dataTableExtended>
                    </CCol>
                </CRow>
            </div>
        </div>
        <div v-show="showIndex==1" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                    <CButton
                        color="edit"
                        class="mr-2"
                        v-c-tooltip="{
                            content: $t('label.return'),
                            placement: 'top',
                        }"
                        size="sm"
                        @click="()=> {showIndex = 0}"
                    >
                        <CIcon name="cil-chevron-left-alt" />
                        
                    </CButton>
                    <CRow class="align-items-center justify-content-start w-100 m-0">
                        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsHousePendingReceive')}`}} </h6>
                    </CRow>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #YardCargoBlStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.YardCargoBlStatus)">
                            {{ item.YardCargoBlStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #VisitBillOfLadingStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                            {{ item.VisitBillOfLadingStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                                square
                                size="sm"
                                color="watch"
                                class="d-flex align-items-center"
                                @click="toggleAdd(item)"
                                v-c-tooltip="{
                                    content: $t('label.reception'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>

                    <template #Weight="{ item }">
                        <td class="text-center">
                            {{ NumberFormat(item.Weight, 2) }}
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==2">
            <BlRecepcion
                :Active="showIndex==2"
                :ContainerItem="ContainerItem"
                :isEdit = "false"
                @Close="showIndex=1, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from './BlReception/reception';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            ContainerItem:{},
            dataDesconsolited: [],
            dataBlHouse: [],
            showIndex: 0,
            YardCargoEventDetailId: ''
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    async function getDesconsolitedList() {
        
        await this.$http.get('YardEventDeconsolidation-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId})
        .then(response => {
            this.dataDesconsolited = response.data.data ?? [];
        })
        .catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            
        });
    }

    async function getBlHousePendingsReception(loading, dataLocalBl) {
        await this.$http.get('YardWBlCargoHousePackaging-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouse = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.blSelected = '';
                    if(dataLocalBl)
                        this.$store.state.yardManagement.blSelected = dataLocalBl.BlCargoId;
                    else
                        this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    async function getYardBlCargo(dataLocalBl) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('BlCargo-by-Id', {BlCargoId: dataLocalBl.BlCargoId})
            .then(response => {
            let res = response.data.data;
            if (res && res?.length>0) {
                this.$store.state.yardManagement.dataBl = res[0];
            }
            })
            .catch( err => {
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
        this.$store.state.yardManagement.loading = false;
    }

    async function Update(loading, dataLocalBl) {
        this.showIndex=1;
        this.ContainerItem={};
        dataLocalBl && await this.getYardBlCargo(dataLocalBl);
        await this.getBlHousePendingsReception(loading, dataLocalBl);
    }

    function toggleDeconsolidation(item) {
        this.YardCargoEventDetailId = item.YardCargoEventDetailId;
        this.showIndex = 1;
    }

    function toggleAdd(item) {
        this.ContainerItem = {...item, YardCargoEventDetailId : this.YardCargoEventDetailId};
        this.showIndex = 2;
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
            return 'danger'
            default:
            return 'success'
        }
    }
    
    //computed
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouse.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpBlName: item.TpBlName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Planned: item.PlannedQuantityReceived ? NumberFormater.formatNumber(item.PlannedQuantityReceived, 2) : '0,00',
                Received: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                RemainReceived: item.QuantityRemainReceived ? NumberFormater.formatNumber(item.QuantityRemainReceived, 2) : '0,00',
                Packaging: Detail,
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`,
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:120px; text-align:center;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'Packaging', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px; text-align:center;', sorter: true, filter: true},  
            { key: 'Planned', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 250px;'},
            { key: 'Received', label: this.$t('label.CertifiedReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 250px;'},
            { key: 'RemainReceived', label: this.$t('label.CertifiedPendingReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 250px;'},
            { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;', sorter: true, filter: true},
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"}     
        ];
    }


    function formatedItems() {
            return this.dataDesconsolited.map((item, index) => {
                return {
                        ...item,
                        Nro: index+1,
                        YardName: item?.YardName? item?.YardName??'' : this.yardData.YardName ?? '',
                        TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                        TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
                        MachineName: item.MachineName ?? ''
                    };
        })
  }

  function fieldsDesconsolidated(){
    return [
        {
          key: 'buttonOptions', 
          label: '', 
          _style: 'min-width: 90px; width: 1%; text-align:center; ', 
          sorter: false, 
          filter: false
        },
        { key: 'number', label: '#',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
        { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'MachineName', label: this.$t('label.machine'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
        { key: 'TransactionDate', label: this.$t('label.eventDate'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
        { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
        { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
        { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
        
    ];
  }


    export default{
        name: 'pendingsBls',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
        },
        methods: {
            toggleAdd,
            toggleDeconsolidation,
            getBlHousePendingsReception,
            Update,
            getYardBlCargo,
            NumberFormat,
            getBadge,
            getDesconsolitedList
        },
        computed: {
            fields,
            formatedItems,
            fieldsDesconsolidated,
            computedListContainer,
            ...mapState({
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                yardData: state => state.yardManagement.yardData,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropBlMovement: state => state.yardManagement.dropBlMovement,
                dataBl: state => state.yardManagement.dataBl,
                dataContainer: state => state.yardManagement.dataContainer,
            })
        },
        watch:{
            dropBlMovement: async function (val) {
                if (val==5) {
                    this.$store.state.yardManagement.loading = true;
                    this.showIndex = 0;
                    this.dataBlHouse = [];
                    await this.getDesconsolitedList();
                    await this.getBlHousePendingsReception(false);
                    this.$store.state.yardManagement.loading = false;
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>