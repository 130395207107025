<template>
  <div>
    <CRow class="my-3" v-if="!isWasRequested">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="backToList()"
        >
          <CIcon name="cil-chevron-left-alt"/>
        </CButton>
        <CRow class="align-items-center justify-content-start w-100 mt-2 m-0">
            <h6>
                {{`${ this.isEdit ? $t('label.editDeconsolidationEvent') : $t('label.newDeconsolidationEvent')}: ${this.DataBl.BlNro}`}} 
            </h6>
        </CRow>
      </CCol>
    </CRow>
    <CRow class="justify-content-center mt-3 px-3">
      <CCol sm="12">
        <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.basicData')">
            <CCard class="card-border">
              <CRow class="mb-4">
                <CCol sm="12" lg="12">
                  <CRow class="justify-content-center" style="font-size: 13px;">
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.declaredHouse')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHouseCount, 2)}`}}</b>
                    </CCol>
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.totalHouseReceived')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHouseQuantityReceived, 2)}`}}</b>
                    </CCol>
                    <CCol class="col-sm-12 col-lg-auto">
                        <span class=" mr-2">{{`${$t('label.totalPlannedHouse')}:`}}</span>
                        <b>{{`${FormatNumber(this.HouseData.BlHousePlannedQuantityReceived, 2)}`}}</b>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.EventReasonId.$model"
                    :options="EventOptions"
                    :label="$t('label.motive')"
                    addLabelClasses="required text-right"
                    :horizontal="{
                      label: 'col-sm-12 col-lg-4', 
                      input: 'col-sm-12 col-lg-8' 
                    }"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventReasonId)"
                    :invalid-feedback="errorMessage($v.EventReasonId)"
                    @change="getEventService"
                    :disabled="isWasRequested"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.EventServiceId.$model"
                    :options="ServiceOptions"
                    :label="$t('label.service')"
                    :addLabelClasses="EventServiceList.length!=0 ? 'required text-right' : 'text-right'"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.EventServiceId)"
                    :invalid-feedback="errorMessage($v.EventServiceId)"
                    :disabled="EventServiceList.length==0 || isWasRequested"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.from')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.fromDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isWasRequested"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="fromDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.fromDay)"
                            size="sm"          
                            :disabled="isWasRequested"            
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    size="sm"
                    v-model="$v.MachineId.$model"
                    :options="MachineOptions"
                    :label="$t('label.machine')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    :is-valid="hasError($v.MachineId)"
                    :invalid-feedback="errorMessage($v.MachineId)"
                    :disabled="isWasRequested" 
                  />
                </CCol>
                <CCol sm="12" lg="6" v-if="false">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.PositioningDate')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.TransactionDate.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="TransactionDate"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.TransactionDate)"
                            size="sm"                      
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row mb-3">
                    <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.to')}`}}</label>
                    <div class="input-group col-sm-12 col-lg-8 mb-0">
                      <vue-datepicker 
                        type="datetime"
                        header
                        :lang="this.$i18n.locale"
                        :editable="false"
                        :clearable="false"
                        format="DD/MM/YYYY HH:mm"
                        placeholder="DD/MM/YYYY HH:mm"
                        time-title-format="DD/MM/YYYY HH:mm"
                        v-model="$v.toDay.$model"
                        :disabled-date="validateDateRange"
                        :append-to-body="false"
                        value-type="format"
                        :show-second="false"
                        class="w-100"
                        :disabled="isWasRequested"
                      >
                        <template #input>
                          <CInput
                            v-uppercase
                            class="w-100 float-left mb-0"
                            :value="toDay"
                            placeholder="DD/MM/YYYY HH:mm"
                            :is-valid="hasError($v.toDay)"
                            size="sm"     
                            :disabled="true"                 
                          >
                            <template #append-content>
                              <CIcon name="cil-calendar" />
                            </template>
                          </CInput>
                        </template>
                        <template #icon-calendar>
                          <div style="display: none"></div>
                        </template>
                      </vue-datepicker>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <CSelect
                    v-if="isEdit"
                    size="sm"
                    :options="statusOptions"
                    :value.sync="Status"
                    :label="$t('label.status')"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    addLabelClasses="required text-right"
                    :is-valid="statusSelectColor"
                    :disabled="isWasRequested"
                  />
                </CCol>
                <CCol sm="12" lg="6">
                  <CTextarea
                    size="sm"
                    rows="2"
                    :label="$t('label.observation')"
                    addLabelClasses="text-right"
                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                    :placeholder="$t('label.select')"
                    v-model="$v.Observation.$model"
                    v-uppercase
                    :is-valid="hasError($v.Observation)"
                    :invalid-feedback="errorMessage($v.Observation)"
                    :disabled="isWasRequested" 
                  />
                </CCol>
                <CCol sm="12" lg="6"></CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2">
        <CRow>
          <CCol sm="12" lg="12" class="d-flex justify-content-end"  v-if="!isWasRequested">
            <CButton
              square
              color="add"
              class="d-flex align-items-center"
              @click="Submit()"
              :disabled="isSubmit"
            >
              <CIcon name="checkAlt"/>{{$t('button.accept')}}
            </CButton>
          </CCol>
          <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="isWasRequested">
              <CButton
                  square
                  color="watch"
                  class="d-flex align-items-center"
                  @click="backToList()"
              >
                  <CIcon name='cil-list'/>{{ $t('label.deconsolidatedList')}}
              </CButton>

              <CButton
                  square
                  color="watch"
                  class="d-flex align-items-center ml-2"
                  @click="NextReception()"
              >
                  <CIcon name='cil-list'/>{{ $t('label.blsHousePendingReceive')}}
              </CButton>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import blDesconsolitedRegisterValidations from '@/_validations/yard-management/events/blDesconsolitedRegisterValidations';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import moment from 'moment';

function data() {
  return {
    isWasRequested: false,
    loadingEdit: false,
    Continue: false,
    misTime: false,
    isSubmit: false,
    Validate: false,
    EventReasonId: '',
    EventList: [],
    EventServiceId: '',
    EventServiceList: [],
    MachineId: '',
    MachineList: [],
    TransactionDate: '',
    fromDay: '',
    toDay: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    Observation: '',
    YardName: '',
    BlockOrArea: '',
    ConditionList: [],
    Status: 0,
    CargoJson: {},
    Seal1: '',
    Seal2: '',
    Seal3: '',
    Seal4: '',
  };
}

async function getYardMachine() {
  await this.$http.get('MachineYard-list', { CompanyBranchId: this.CompanyBranchId, YardId: this.yardData.YardId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardEventReasonList() {
  let Parameter = {
    EventId: process.env.VUE_APP_EVENT_DESCONSOLIDATION,
    TpCargoId: this.DataBl.TpCargoId,
    CargoId: this.DataBl.CargoId,
    YardId: this.yardData.YardId,
  };
  await this.$http.get('YardWEventReason-list', Parameter)
    .then(response => {
      let List = response.data.data;
      this.EventList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function isContinue() {
  try{
    this.$v.fromDay.$touch();
    this.$v.toDay.$touch()
    if (this.$v.fromDay.$error || this.$v.toDay.$error){
      if (!this.fromDay) 
        throw this.$t('label.TheDateFromIsRequired');

      if (!this.toDay) 
        throw this.$t('label.TheDateToIsRequired');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate) 
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate || DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate) 
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm") || DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) 
        throw this.$t('label.ErrorDispatchDatePleaseCheck');

      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay)) 
        throw this.$t('label.ErrorFromToPleaseCheck');
      
      if (DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
        throw this.$t('label.ErrorToFromPleaseCheck');
    }

    this.Continue = true;
    this.$v.$reset();
  }
  catch(e){
    
    this.notifyError({text: e});
  }
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
        throw this.$t('label.ErrorDispatchDatePleaseCheck');
      }
      if (DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
        throw this.$t('label.ErrorFromToPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let BlCargoJson = {
      BlCargoId: this.DataBl.BlCargoId,
      EventServiceId: this.EventServiceId,
      EventReasonId: this.EventReasonId,
      MachineId: this.MachineId,
      Observation: this.Observation,
      TransactionBegin: this.fromDay ? DateFormater.formatDateTimeWithoutSlash(this.fromDay) : '', 
      TransactionFinish: this.toDay ? DateFormater.formatDateTimeWithoutSlash(this.toDay) : '',
     // TransactionDate: !this.misTime ? DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) : undefined,
      Seal1: this.Seal1,
      Seal2: this.Seal2,
      Seal3: this.Seal3,
      Seal4: this.Seal4
    }

    if (this.isEdit) {
      BlCargoJson.BlCargoId = this.ContainerItem.BlCargoId;
      BlCargoJson.YardCargoEventDetailId = this.ContainerItem.YardCargoEventDetailId;
      BlCargoJson.Status = this.Status;
    }

    let Method = this.isEdit ? 'PUT' : 'POST';
    let Route = this.isEdit ? 'YardWCargoDeconsolidationGeneral-update' : 'YardWCargoDeconsolidationGeneral';

    this.$http.ejecutar(Method, Route, BlCargoJson, { root: 'BlCargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data?.[0].Response});
        //this.$emit("Update-list", this.ContainerItem);
        this.isSubmit = false;
        this.isWasRequested = true;
        this.$store.state.yardManagement.loading = false;
        this.Reset(this.isEdit? true : false);
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function getEventService(event) {
  this.EventReasonId = event.target.value;
  this.EventServiceId = '';
  this.EventServiceList = [];
  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData.EventServiceJson : [];
  }
}

function FormatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}


function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function validateDateInitRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.fromDay) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) 
    return true;
  
  return false;
}

function validateDateFinalRange() {
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) < this.ValidPreviousDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) > this.ValidLaterDate ) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) 
    return true;
  
  if(DateFormater.formatDateTimeWithoutSlash(this.toDay) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) 
    return true;
  
  return false;
}

function getPositionJson(PositionJson, Nominal) {
  if (PositionJson) {
    if (Nominal) {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS==PreviousDetail.YardSlotStatus?.toUpperCase())
    } else {
      return PositionJson.filter(PreviousDetail => process.env.VUE_APP_YARD_SLOT_STATUS!=PreviousDetail.YardSlotStatus?.toUpperCase())
    }
  }else{
    return [];
  }
}

function ChangeTime() {

  if(!this.misTime){
    this.fromDay = '';
    this.toDay = '';
    this.Continue = false;
    this.$v.$reset();
  }

  else{
    this.TransactionDate = ''
    this.$v.$reset();
  }
}

function getNewData() {
  this.$v.$reset();
}

async function backToList() {
  this.$store.state.yardManagement.loading = true;
  this.$emit("Update-list");
  this.Reset();
}

async function NextReception() {
  this.$store.state.yardManagement.loading = true;
  this.$emit("Update-list");
  this.Reset();
  this.$store.state.yardManagement.dropBlMovement  = 5;
}

async function getdata(val) {
  this.loadingEdit = true;

  this.Continue = true;
  this.misTime = true; 
  this.TransactionDate = val.TransactionDate;
  this.fromDay = DateFormater.formatDateTimeWithSlash(val.TransactionBegin);
  this.toDay = val.TransactionFinish ? DateFormater.formatDateTimeWithSlash(val.TransactionFinish) : '';

  this.EventReasonId = val.EventReasonId ?? '';
  this.EventServiceId = val.EventServiceId ?? '';
  this.MachineId = val.MachineId ?? '';
  this.Observation = val.Observation ?? '';
  this.Status = val.FgActYardCargoEventDetail ? 1 : 0;
  this.CargoJson = {
    YardCargoEventDetailId: this.ContainerItem.YardCargoEventDetailId,
    YardCargoId: this.DataBl?.YardCargoId,
    EventServiceId: this.EventServiceId,
    EventReasonId: this.EventReasonId,
    MachineId: this.MachineId,
    Seal1: '',
    Seal2: '',
    Seal3: '',
    Seal4: '',
    TransactionDate: val.TransactionDate,
    Observation: this.Observation,
    Status: this.Status,
  }

  if (this.EventReasonId) {
    let EventReasonData = this.EventList.find(item => item.EventReasonId == this.EventReasonId);
    this.EventServiceList = EventReasonData?.EventServiceJson ? EventReasonData?.EventServiceJson : [];
  }

  this.Validate = true;
  this.$v.$touch();
  this.loadingEdit = false;
}

function getDateRange() {
  this.previousDate = DateFormater.formatDateRange(this.DataBl.ReceptionDate) 
  this.laterDate = new Date();
  this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.DataBl.ReceptionDate)) 
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function statusSelectColor() {
  return this.Status === 1;
}

function Reset(isClose = true) {
  if(isClose)
    this.isWasRequested = false;

  this.misTime = false;
  this.fromDay = '';
  this.toDay = '';
  this.EventReasonId = '',
  this.EventList = [],
  this.EventServiceId = '',
  this.EventServiceList = [],
  this.TransactionDate = '',
  this.ValidPreviousDate = '',
  this.ValidLaterDate = '',
  this.previousDate = '',
  this.laterDate = '',
  this.Observation = '',
  this.YardName = '';
  this.MachineId = '';
  this.MachineList = [];
  this.ConditionList = [];
  this.Status = 0;
  this.CargoJson = {};
  this.Validate = false;
  this.$v.$reset();
  isClose && this.$emit("Close");
}

//Computeds:
function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function EventOptions() {
  if(this.EventList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventList.map(function(e){
      chart.push({
        value: e.EventReasonId,
        label: e[`EventReasonName${_lang}`],
      })
    })
    return chart;
  }
}

function ServiceOptions() {
  if(this.EventServiceList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    this.EventServiceList.map(function(e){
      chart.push({
        value: e.EventId,
        label: e[`EventName${_lang}`],
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.DataBl?.TpCargoStatusId?.toUpperCase();
}

function ContainerSize() {
  return this.DataBl?.Size != 20;
}

export default {
  name: 'positioning-register',
  components: {
   },
  props: { Active: Boolean, isEdit: Boolean, ContainerItem: Object, HouseData: Object },
  data,
  mixins: [
    ModalMixin
  ],
  validations(){
    return blDesconsolitedRegisterValidations ( this.ValidPreviousDate, this.ValidLaterDate,
      moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm") ,this.EventServiceList.length!=0, 
      this.fromDay, this.toDay, this.misTime);
  },
  directives: UpperCase,
  methods: {
    getYardMachine,
    getYardEventReasonList,
    Submit,
    getEventService,
    validateDateRange,
    getPositionJson,
    getNewData,
    getdata,
    getDateRange,
    statusSelectColor,
    Reset,
    ChangeTime,
    backToList,
    NextReception,
    isContinue,
    FormatNumber
  },
  computed: {
    validateDateInitRange,
    validateDateFinalRange,
    MachineOptions,
    EventOptions,
    ServiceOptions,
    ValidateTpCargoStatus,
    ContainerSize,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      DataBl: state => state.yardManagement.dataBl,
      dropBlMovement: state => state.yardManagement.dropBlMovement,
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        this.Reset(false);
        this.$store.state.yardManagement.loading = true;
        this.YardName = this.DataBl.YardName;
        await this.getDateRange();
        await this.getYardMachine();
        await this.getYardEventReasonList();
        if (this.isEdit) {
          await this.getdata(this.ContainerItem);
        }else{
          this.Continue = true;
          this.misTime = true;
          
          await this.getNewData();
        }
        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    fromDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.toDay) ) {
            throw this.$t('label.ErrorFromToPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    toDay: async function(val){
      try{
        if(val){
          if(!this.loadingEdit){
            this.Continue = false;
          }
          
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) >= moment(this.DataBl.DispatchDate).format("YYYY-MM-DD HH:mm")) {
            throw this.$t('label.ErrorDispatchDatePleaseCheck');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.fromDay) ) {
            throw this.$t('label.ErrorToFromPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    }
  },
};
</script>
<style>
.text-size-11{
  font-size: 11px;
}
.card-border{
  padding: 20px;
  background-color: #fff;
  border-color: #c4c9d0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
</style>