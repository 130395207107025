@@ -0,0 +1,2758 @@
<template>
  <form @submit.prevent="submitBody">
    <CModalExtended
      :title=title
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      size="xl"
      @update:show="resetForm"
    >
      <CRow class="justify-content-center align-items-center m-0">
        <div class="px-1 mb-3 text-center">
          <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
            <div class="pr-1">
              <CIcon name="Quantity" class="mr-1"/><b>{{ $t('label.Cant') }}</b>
            </div>
            <div>
              <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.Quantity, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.QuantityReceived, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.QuantityCertified, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.QuantityDispatched, 2) }}</div>
            </div>
          </div>
        </div>

        <div class="px-1 mb-3 text-center">
          <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
            <div class="pr-1">
              <CIcon name="Weight" class="mr-1"/><b>{{ `(KGM)` }}</b>
            </div>
            <div>
              <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.Weight, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.WeightReceived, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.WeightCertified, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.WeightDispatch, 2) }}</div>
            </div>
          </div>
        </div>
        
        <div class="px-1 mb-3 text-center">
          <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
            <div class="pr-1">
              <CIcon name="boxFill" class="mr-1"/><b>{{ `(M³)` }}</b>
            </div>
            <div>
              <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.Volumen, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.VolumenReceived, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.VolumenCertified, 2) }}</div>
            </div>
            <div class="px-1">-</div>
            <div>
              <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
              <div>{{ formatNumber(form.dataBasic.VolumenDispatch, 2) }}</div>
            </div>
          </div>
        </div>
      </CRow>
      <CustomTabs :active-tab="tabIndex" @update:activeTab="handleTab" class="text-table">
        <CustomTab :class="$v.form.dataBasic.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.basicData')}}</span>
          </template>
          
          <DataBasicTab
            :form="form"
            :vForm="$v.form"
            :items = "items"
            :isEdit="isEdit"
            :showModal="modal"
            :Tab="tabIndex==0 && modal"
            :fgHouse="fgHouse"
            :fgInfo="fgInfo"
            :validCargoMaster = "validCargoMaster"
            :Bl = "Bl"
            @VisitOrItineraryList="VisitOrItineraryList=$event"
            @UpdateCommodityList="CommodityList=$event"
          />
        </CustomTab>

        <CustomTab :class="$v.form.PackagingList.$error ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.packaging')}}</span>
          </template>

          <PackagingTab
            :form="form"
            :CommodityList="CommodityList"
            :vForm="$v.form"
            :showModal="modal"
            :validCargoMaster="validCargoMaster"
            :Tab="tabIndex==1 && modal"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.Serialization')}}</span>
          </template>

          <SerializationTab
            :form="form"
            :CommodityList="CommodityList"
            :vForm="$v.form"
            :showModal="modal"
            :isEdit="isEdit"
            :Tab="tabIndex==2 && modal"
            :fgInfo="fgInfo"
            @FgSerialOrigin="FgSerialOrigin=$event"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.imdg')}}</span>
          </template>

          <ImdgTab
            :showModal="modal"
            :Tab="tabIndex==3 && modal"
            :form="form"
            :imdgItems = "imdgItems"
            :validCargoMaster = "validCargoMaster"
            :fgInfo="true"
            @deleteImdg="(event) => deleteImdg(event)"
            @imdgUpdateItems="(event)=>{submitImdg(event)} "
          />
        </CustomTab>

        <CustomTab :class="$v.form.seller.$error ? 'tab-error' :''">
          <template #title>
            <span>{{getClientLabel}}</span>
          </template>

          <ClientTab
            :showModal="modal"
            :Tab="tabIndex==4 && modal"
            :form="form"
            :vForm="$v.form"
            :ClientLabel="getClientLabel"
            :validCargoMaster="validCargoMaster"
            :isEdit="isEdit"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab :class="$v.form.customBroke.$error || $v.form.consolidator.$error ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.customBroker')}}</span>
          </template>

          <CustomsBrokerTab
            :showModal="modal"
            :Tab="tabIndex==5 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab :class="$v.form.consignee.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{getConsigneeLabel}}</span>
          </template>

          <ConsigneeTab
            :showModal="modal"
            :Tab="tabIndex==6 && modal"
            :form="form"
            :vForm="$v.form"
            :isExporter="isExporter"
            :isImporter="isImporter"
            :Activity="form.dataBasic.PortActivityId"
            :isEdit="isEdit"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab :class="$v.form.notify.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.notifyParty')}}</span>
          </template>
          
          <NotifyPartyTab
            :showModal="modal"
            :Tab="tabIndex==7 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab :class="$v.form.DescriptionOfGoods.DescriptionOfGoods.$anyError ? 'tab-error' :''">
          <template #title>
            <span>{{$t('label.inventoryContains')}}</span>
          </template>

          <DiceContenerTab
            :showModal="modal"
            :Tab="tabIndex==8 && modal"
            :form="form"
            :vForm="$v.form"
            :isEdit="isEdit"
            :validCargoMaster = "validCargoMaster"
            :fgInfo="fgInfo"
          />
        </CustomTab>

        <CustomTab>
          <template #title>
            <span>{{$t('label.documentation')}}</span>
          </template>

          <DocumentationTab
            :showModal="modal"
            :Tab="tabIndex==9 && modal"
            :form="form"
            :Items = "documentItems"
            :fgInfo="fgInfo"
            @documentItems = "(event)=> documentItems = event"
          />
        </CustomTab>

        <CustomTab  v-if="isEdit">
          <template #title>
            <span>{{$t('label.CertificatesEntry')}}</span>
          </template>

          <CertificateEntryTab
            :certificateEntryList="certificateEntryList"
          />
        </CustomTab>
        
        <CustomTab v-if="isEdit">
          <template #title>
            <span>{{$t('label.status')}}</span>
          </template>

          <BlStatusTab
            :showModal="modal"
            :Tab="tabIndex==11 && modal"
            :form="form"
            :ContainerItem = "Bl"
            :Items = "documentItems"
            @documentItems = "(event)=> documentItems = event" 
          />
        </CustomTab>
      </CustomTabs>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="submitBody()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>

    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BlValidations from '@/_validations/bl-query/blValidations';
import { Money } from "v-money";
import PackagingTab from './tabs/packaging-tab';
import DataBasicTab from './tabs/dataBasic-tab';
import SerializationTab from './tabs/serialization-tab';
import ImdgTab from '@/pages/yard-management/container/bl/tabs/imdg-tab';
import ClientTab from './tabs/client-tab';
import CustomsBrokerTab from '@/pages/yard-management/container/bl/tabs/customsBroker-tab';
import ConsigneeTab from './tabs/consignee-tab';
import NotifyPartyTab from '@/pages/yard-management/container/bl/tabs/notifyParty-tab';
import DiceContenerTab from '@/pages/yard-management/container/bl/tabs/diceContener-tab';
import CertificateEntryTab from '@/pages/yard-management/container/bl/tabs/certificateEntry-tab';
import DocumentationTab from './tabs/documentation-tab';
import BlStatusTab from '@/pages/yard-management/container/bl/tabs/blStatus-tab';
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    //Modal General
    modalActive: false,
    show: false,
    tabIndex: 0,
    showModal: false,
    Loading: false,
    isSubmit: false,
    headingJsonDB: [],
    CommodityList: [],
    
    //DataBasic
    masterId: process.env.VUE_APP_TP_BL_ID_MASTER,
    houseId: process.env.VUE_APP_TP_BL_ID_HOUSE,
    VisitOrItineraryList: [],

    //Imdg
    imdgItems: [],

    //Certificate of Entry
    certificateEntryList: [],

    //Documentation
    documentItems: [],
    documentItemsDB: [],

    //Validations
    validCargoMaster: false,

    //Form
    form: {
      dataBasic:{
        BlCargoId: '',
        bl: '',
        TpBlId: '',
        BlCargoMasterId: '',
        MasterBlNro: '',
        VesselId: '',
        VisitIdOrItineraryId: '',
        PortActivityId: '',
        Pol: '',
        Pod: '',
        TpCargoId: '',
        TpCargoClassifId: '',
        CommodityId: '',
        BookingNro: '',
        Weight: '',
        WeightReceived: '',
        WeightDispatch: '',
        WeightCertified: '',
        Procedence: '',
        Volumen: '',
        VolumenReceived: '',
        VolumenDispatch: '',
        VolumenCertified: '',
        Quantity: '',
        QuantityReceived: '',
        QuantityDispatched: '',
        QuantityCertified: '',
        Viscosity: '',
        Temperature: '',
        headingJson: [],
        customsRegime:'',
        ReceptionBpAct: '',
        Dua: '',
        Status: 1,
        FgClassification: false,
      },
      Packaging: {
        Id: '',
        PackagingOrigin: '',
        SerialOrigin: '',
        WeightOrigin: 0,
        CommodityId: '',
        CommodityName: '',
        HeadingName: '',
        Serial: '',
        PackagingId: '',
        PackagingNameEn: '',
        PackagingNameEs: '',
        DescriptionOfGoods: '',
        Weight: 0, 
        Quantity: 0,
        Volumen: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        FgPackaging: false,
        FgRequiredSerialization: false,
        FgSerialOrigin: false,
      },
      PackagingList: [],
      serialization: {
        Id: '',
        PackagingId: '',
        CommodityName: '',
        Serial: '',
        DescriptionOfGoods: '',
        Length: 0,
        Width: 0,
        Height: 0,
        Weight: 0, 
        Volumen: 0,
      },
      SerializationList: [],
      imdg:{
        Id: 0,
        BlCargoMasterImdgId:'',
        ImdgId: '',
        ImdgClassId: '',
        PackagingGroupId: '',
        UnNumberId: '',
        DescriptionOfGoodsDGS: '',
        Status: 1
      },
      seller:{
        SellerName: '',
        SellerAddress: '',
        SellerPrimaryPhone: '',
        SellerSecondaryPhone: '',
        SellerPrimaryEmail: '',
        SellerSecondaryEmail: ''
      },
      customBroke:{
        CustomBrokerId: '',
        CustomBrokerRif: '',
        CustomBrokerName: '',
        CustomBrokerAddress: '',
        CustomBrokerPrimaryPhone: '',
        CustomBrokerSecondaryPhone: '',
        CustomBrokerPrimaryEmail: '',
        CustomBrokerSecondaryEmail: '',
        consolidatorCheck: false,
      },
      consolidator:{
        ConsolidatorId: '',
        ConsolidatorRif: '',
        ConsolidatorName: '',
        ConsolidatorAddress: '',
        ConsolidatorPrimaryPhone: '',
        ConsolidatorSecondaryPhone: '',
        ConsolidatorPrimaryEmail: '',
        ConsolidatorSecondaryEmail: ''
      },
      consignee:{
        ClientId: '',
        ConsigneeId: '',
        ConsigneeRif: '',
        ConsigneeAddress: '',
        ConsigneeName: '',
        ConsigneePrimaryPhone: '',
        ConsigneeSecondaryPhone: '',
        ConsigneePrimaryEmail: '',
        ConsigneeSecondaryEmail: '', 
      },
      notify:{
        NotifyToName: '',
        NotifyToAddress: '',
        NotifyToPrimaryPhone: '',
        NotifyToSecondaryPhone: '',
        NotifyToPrimaryEmail: '',
        NotifyToSecondaryEmail: ''
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: ''
      },
      status:{
        Status: '',
        TransactionDate: '',
        Motivo: '',
      },
    },
  };
}

//-----------------------   Method    ------------------------
function handleTab(tab) {
  this.tabIndex = tab;
}

function submitBody() {
  try {
    this.isSubmit = true;
    this.$store.state.yardManagement.loading = true;

    this.$v.form.dataBasic.$touch();
    this.$v.form.PackagingList.$touch();
    this.$v.form.seller.$touch();
    this.$v.form.customBroke.$touch();
    this.$v.form.consolidator.$touch();
    this.$v.form.consignee.$touch();
    this.$v.form.notify.$touch();
    this.$v.form.DescriptionOfGoods.$touch();

    if (this.$v.form.dataBasic.$error || this.$v.form.seller.$error || this.$v.form.customBroke.$error || this.$v.form.consolidator.$error || this.$v.form.consignee.$error || 
      this.$v.form.notify.$error || this.$v.form.DescriptionOfGoods.$error || this.$v.form.PackagingList.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }
  
    let headingJson = [];

    let TotalheadingJson = [...this.headingJsonDB, ...this.form.dataBasic.headingJson];
    TotalheadingJson.map(item => {
      if (!headingJson.some(heading => {return heading.HeadingId == item.HeadingId})) {
        headingJson.push(item);
      }
    });

    this.documentItemsDB.map((e) => {
      if ( this.documentItems.filter(el => el.BlCargoDocumentId == e.BlCargoDocumentId).length == 0 )
      {
        e.Status = "INACTIVO";
      }
    })
    

    let DocumentJson = this.documentItemsDB.map((e) =>{
      return {
        BlCargoDocumentId: e.BlCargoDocumentId ?? '',
        PortActivictyDocumentCargoId: e.PortActivictyDocumentCargoId ?? '',
        BlCargoDocumentDs: e.BlCargoDocumentDs ?? '',
        RouteBlCargoDocument: e.RouteBlCargoDocument ?? '',
        Status: e.Status == "ACTIVO" ? 1 : 0,
      }
      }
    );
 
    this.documentItems.map((e) =>{
        DocumentJson.push({
          BlCargoDocumentId: e.BlCargoDocumentId ?? '',
          PortActivictyDocumentCargoId: e.PortActivictyDocumentCargoId ?? '',
          BlCargoDocumentDs: e.BlCargoDocumentDs ?? '',
          RouteBlCargoDocument: e.RouteBlCargoDocument ?? '',
          Status: 1,
        });
      }
    )

    let Vessel = this.VisitOrItineraryList?.map(e => {return {...e, value: e.VisitId ?? e.ItineraryId}})?.find(e => e.value == this.form.dataBasic.VisitIdOrItineraryId);

    let BlCargoJson = {
      BlCargoId: this.isEdit ? this.form.dataBasic.BlCargoId : '', 
      BlCargoMasterId: this.form.dataBasic.BlCargoMasterId,
      TpBlId: this.form.dataBasic.TpBlId, 
      ItineraryId: Vessel?.ItineraryId ?? '',
      VisitId: Vessel?.VisitId ?? '',
      TpCargoId: this.form.dataBasic.TpCargoId,
      TpCargoClassifId: this.form.dataBasic.TpCargoClassifId,
      BlNro: this.form.dataBasic.bl,
      PortActivityId: this.form.dataBasic.PortActivityId,
      LoadPortId: this.form.dataBasic.Pol,
      DischargePortId: this.form.dataBasic.Pod,
      BookingNro: this.form.dataBasic.BookingNro,
      Weigth: this.form.dataBasic.Weight,
      Procedence: this.form.dataBasic.Procedence,
      Volumen: this.form.dataBasic.Volumen,
      Temperature: this.form.dataBasic.Temperature,
      Quantity: this.form.dataBasic.Quantity,
      Viscosity: this.form.dataBasic.Viscosity,
      ReceptionBpAct: this.form.dataBasic.ReceptionBpAct,
      Dua: this.form.dataBasic.Dua,
      CustomOperationId: this.form.dataBasic.customsRegime,
      HeadingJson: headingJson.map(item =>{
        let ValidatePreviousHeading = this.headingJsonDB.find(e => {
          return e.HeadingId == item.HeadingId;
        })
        let ValidateCurrentHeading = this.form.dataBasic.headingJson.some(heading => {
          return heading.HeadingId == item.HeadingId;
        })
        return {
          BlCargoHeadingId: ValidatePreviousHeading?.BlCargoHeadingId ?? '',
          HeadingId: item.HeadingId,
          CommodityId: item.CommodityId,
          Status: ValidateCurrentHeading ? 1 : 0,
        }
      }),
      DetailJson: this.form.PackagingList.map(e => {
        return {
          BlCargoMasterDetailId: e.BlCargoMasterDetailId ?? '',
          BlCargoMasterDetailParentId: e.BlCargoMasterDetailParentId ?? '',
          BlCargoMasterDetailSerialParentId: e.BlCargoMasterDetailSerialParentId ?? '',
          WeigthParent: e.WeigthParent ?? 0,
          PackagingId: e.PackagingId ?? '',
          HeadingId: e.HeadingId ?? '',
          CommodityId: e.CommodityId ?? '',
          Quantity: e.Quantity ?? 0,
          Length: e.Length ?? 0,
          Width: e.Width ?? 0,
          Height: e.Height ?? 0,
          Volumen: e.Volumen ?? 0,
          Weigth: e.Weight ?? 0,
          SquareMeters: e.SquareMeters ?? 0,
          FgExternPackaging: e.FgExternPackaging ?? false,
          FgRequiredSerialization: e.FgRequiredSerialization ?? false,
          DescriptionOfGoods: e.DescriptionOfGoods ?? '',
          ImdgJson: e.ImdgJson.map(e => {
            return {
              BlCargoMasterImdgId: e.BlCargoMasterImdgId ?? '',
              ImdgClassId: e.ImdgClassId ?? '',
              ImdgId: e.ImdgId ?? '',
              UnNumberId: e.UnNumberId ?? '',
              PackagingGroupId: e.PackagingGroupId ?? '',
              DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS ? e.DescriptionOfGoodsDGS : '',
              Status: e.Status,
            }
          }),
          Status: e.Status == 1 ? 1 : 0,
        }
      }),
      SerialJson: this.form.SerializationList.map(e => {
        return {
          BlCargoMasterDetailSerialId: e.BlCargoMasterDetailSerialId ?? '',
          Serial: e.Serial ?? '',
          Quantity: e.Quantity ?? 0,
          Length: e.Length ?? 0,
          Width: e.Width ?? 0,
          Height: e.Height ?? 0,
          Volumen: e.Volumen ?? 0,
          Weigth: e.Weight ?? 0,
          SquareMeters: e.SquareMeters ?? 0,
          FgExternPackaging: e.FgExternPackaging ?? false,
          DescriptionOfGoods: e.DescriptionOfGoods ?? '',
          ImdgJson: e.ImdgJson.map(e => {
            return {
              BlCargoMasterImdgId: e.BlCargoMasterImdgId ?? '',
              ImdgClassId: e.ImdgClassId ?? '',
              ImdgId: e.ImdgId ?? '',
              UnNumberId: e.UnNumberId ?? '',
              PackagingGroupId: e.PackagingGroupId ?? '',
              DescriptionOfGoodsDGS: e.DescriptionOfGoodsDGS ? e.DescriptionOfGoodsDGS : '',
              Status: e.Status,
            }
          }),
          Status: e.Status == 1 ? 1 : 0,
        }
      }),
      
      CustomBrokerId: this.form.customBroke.CustomBrokerId,
      ConsolidatorId: this.form.consolidator.ConsolidatorId,

      SellerName: this.form.seller.SellerName,
      SellerAddress: this.form.seller.SellerAddress,
      SellerPrimaryPhone: this.form.seller.SellerPrimaryPhone,
      SellerSecondaryPhone: this.form.seller.SellerSecondaryPhone,
      SellerPrimaryEmail: this.form.seller.SellerPrimaryEmail,
      SellerSecondaryEmail: this.form.seller.SellerSecondaryEmail,

      ClientTpId: this.form.consignee.ConsigneeId,
      ClientId: this.form.consignee.ClientId,
      ClientName: this.form.consignee.ConsigneeName,
      ClientAddress: this.form.consignee.ConsigneeAddress,
      ClientPrimaryPhone: this.form.consignee.ConsigneePrimaryPhone,
      ClientSecondaryPhone: this.form.consignee.ConsigneeSecondaryPhone,
      ClientPrimaryEmail: this.form.consignee.ConsigneePrimaryEmail,
      ClientSecondaryEmail: this.form.consignee.ConsigneeSecondaryEmail,
      
      NotifyToName: this.form.notify.NotifyToName,
      NotifyToAddress: this.form.notify.NotifyToAddress,
      NotifyToPrimaryPhone: this.form.notify.NotifyToPrimaryPhone,
      NotifyToSecondaryPhone: this.form.notify.NotifyToSecondaryPhone,
      NotifyToPrimaryEmail: this.form.notify.NotifyToPrimaryEmail,
      NotifyToSecondaryEmail: this.form.notify.NotifyToSecondaryEmail,

      DescriptionOfGoods: this.form.DescriptionOfGoods.DescriptionOfGoods,
      DocumentJson: DocumentJson,

      Status: this.form.dataBasic.Status,
    }
    
    let metodo = 'POST';
    let ruta = 'BlCargo-insert';

    this.$http.ejecutar(metodo, ruta, BlCargoJson, { root: 'BlCargoJson' })
      .then(response => {
        let ResponseJson = response.data.data[0].ResponseJson;
        if (ResponseJson.length == 0) {
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data[0].Response,
            type: "success"
          });
          this.$emit('Update-list', response.data.data[0]);
          this.resetForm();
        }else{
          let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
          this.$store.state.yardManagement.loading = false;
          ResponseJson.map(item => {
            throw item[`ErrorMessage${_lang}`];
          })
        }
      }).catch(err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      }).then(() => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
      });
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: error,
      type: "error"
    });
  }
}

function getData(val) { 
  //dataBasic
  this.form.dataBasic.BlCargoId = val.BlCargoId ?? '';
  this.form.dataBasic.BlCargoMasterId = val.BlCargoMasterId ?? '';
  this.form.dataBasic.MasterBlNro = val.MasterBlNro ?? '';
  this.form.dataBasic.TpBlId = val.TpBlId ?? '';
  this.form.dataBasic.VesselId = val.VesselId ?? '';
  this.form.dataBasic.VisitIdOrItineraryId = val.VisitId ?? (val.ItineraryId ?? '');
  this.form.dataBasic.TpCargoId = val.TpCargoId ?? '';
  this.form.dataBasic.TpCargoClassifId = val.TpCargoClassifId ?? '';
  this.form.dataBasic.bl = val.BlNro ?? '';
  this.form.dataBasic.PortActivityId = val.PortActivityId ?? '';
  this.form.dataBasic.Pol = val.LoadPortId ?? '';
  this.form.dataBasic.Pod = val.DischargePortId ?? '';
  this.form.dataBasic.BookingNro = val.BookingNro ?? '';
  this.form.dataBasic.Procedence = val.Procedence ?? '';
  this.form.dataBasic.Temperature = val.Temperature ?? '';
  this.form.dataBasic.Viscosity = val.Viscosity ?? '';
  this.form.dataBasic.ReceptionBpAct = val.ReceptionBpAct ?? '';
  this.form.dataBasic.Dua = val.Dua ?? '';
  this.form.dataBasic.customsRegime = val.CustomOperationId ?? '';
  this.headingJsonDB = val?.HeadingJson ?? [];
  this.form.dataBasic.headingJson = val.HeadingJson ?? [];
  this.form.dataBasic.Status = val.FgActBlCargoMaster? 1:0;
  this.form.dataBasic.QuantityReceived = val.QuantityReceived ?? 0;
  this.form.dataBasic.QuantityDispatched = val.QuantityDispatched ?? 0;
  this.form.dataBasic.QuantityCertified = val.QuantityCertified ?? 0;
  this.form.dataBasic.WeightReceived = val.WeigthReception ?? 0;
  this.form.dataBasic.WeightDispatch = val.WeigthDispatch ?? 0;
  this.form.dataBasic.WeightCertified = val.WeigthCertified ?? 0;
  this.form.dataBasic.VolumenReceived = val.VolumenReception ?? 0;
  this.form.dataBasic.VolumenDispatch = val.VolumenDispatch ?? 0;
  this.form.dataBasic.VolumenCertified = val.VolumenCertified ?? 0;

  //detail
  let SerialJson = [];
  this.form.PackagingList = val?.DetailJson?.map((e, index) => {
    if (e?.SerialJson) {
      SerialJson.push(...e.SerialJson);
    }
    return {
      ...e, 
      IdX: index+1, 
      Weight: e.Weigth, 
      ImdgJson: e?.ImdgJson?.map((el, index2) => {
        return {
          ...el,
          IdX: index2+1,
          ImdgClassId: el.ImdgClassId ?? '',
          ImdgId: el.ImdgId ?? '',
          UnNumberId: el.UnNumberId ?? '',
          PackagingGroupId: el.PackagingGroupId ?? '',
          Status: el.FgActBlCargoImdg ? 1 : 0,
        }
      }) ?? [],
      Status: 1}
  }) ?? [];

  //Serialization
  this.form.SerializationList = SerialJson.map((e, index) => {
    return {
      ...e, 
      IdX: index+1, 
      Weight: e.Weigth, 
      ImdgJson: e?.ImdgJson?.map((el, index2) => {
        return {
          ...el,
          IdX: index2+1,
          ImdgClassId: el.ImdgClassId ?? '',
          ImdgId: el.ImdgId ?? '',
          UnNumberId: el.UnNumberId ?? '',
          PackagingGroupId: el.PackagingGroupId ?? '',
          Status: el.FgActBlCargoImdg ? 1 : 0,
        }
      }) ?? [],
      Status: 1
    }
  });

  //IMDG
  this.imdgItems = val?.ImdgJson?.map((e, index) => {return {...e, IdX: index+1, Status: 1}}) ?? [];

  //seller
  this.form.seller.SellerName = val?.SellerName ?? '';
  this.form.seller.SellerAddress = val?.SellerAddress ?? '';
  this.form.seller.SellerPrimaryPhone = val?.SellerPrimaryPhone ?? '';
  this.form.seller.SellerSecondaryPhone = val?.SellerSecondaryPhone ?? '';
  this.form.seller.SellerPrimaryEmail = val?.SellerPrimaryEmail ?? '';
  this.form.seller.SellerSecondaryEmail = val?.SellerSecondaryEmail ?? '';

  //customBroke
  this.form.customBroke.CustomBrokerId = val?.CustomBrokerId ?? '';
  this.form.customBroke.CustomBrokerRif = val?.CustomBrokerRif ?? '';
  this.form.customBroke.CustomBrokerName =  val?.CustomBrokerName ?? '';
  this.form.customBroke.CustomBrokerAddress = val?.CustomBrokerAddress ?? '';
  this.form.customBroke.CustomBrokerPrimaryPhone = val?.CustomBrokerPrimaryPhone ?? '';
  this.form.customBroke.CustomBrokerSecondaryPhone = val?.CustomBrokerSecondaryPhone ?? '';
  this.form.customBroke.CustomBrokerPrimaryEmail = val?.CustomBrokerPrimaryEmail ?? '';
  this.form.customBroke.CustomBrokerSecondaryEmail = val?.CustomBrokerSecondaryEmail ?? '';
  
  //consolidator
  this.form.consolidator.ConsolidatorId = val?.ConsolidatorId ?? '';
  this.form.consolidator.ConsolidatorRif = val?.ConsolidatorRif ?? '';
  this.form.consolidator.ConsolidatorName = val?.ConsolidatorName ?? '';
  this.form.consolidator.ConsolidatorAddress = val?.ConsolidatorAddress ?? '';
  this.form.consolidator.ConsolidatorPrimaryPhone = val?.ConsolidatorPrimaryPhone ?? '';
  this.form.consolidator.ConsolidatorSecondaryPhone = val?.ConsolidatorSecondaryPhone ?? '';
  this.form.consolidator.ConsolidatorPrimaryEmail = val?.ConsolidatorPrimaryEmail ?? '';
  this.form.consolidator.ConsolidatorSecondaryEmail = val?.ConsolidatorSecondaryEmail ?? '';

  //consignee
  this.form.consignee.ConsigneeId = val?.ClientTpId ?? '';
  this.form.consignee.ConsigneeRif = val?.ClientRif ?? '';
  this.form.consignee.ConsigneeAddress = val?.ClientAddress ?? '';
  this.form.consignee.ConsigneeName = val?.ClientName ?? '';
  this.form.consignee.ConsigneePrimaryPhone = val?.ClientPrimaryPhone ?? '';
  this.form.consignee.ConsigneeSecondaryPhone = val?.ClientSecondaryPhone ?? '';
  this.form.consignee.ConsigneePrimaryEmail = val?.ClientPrimaryEmail ?? '';
  this.form.consignee.ConsigneeSecondaryEmail = val?.ClientSecondaryEmail ?? '';
 
  //notify
  this.form.notify.NotifyToName = val?.NotifyToName ?? '';
  this.form.notify.NotifyToAddress = val?.NotifyToAddress ?? '';
  this.form.notify.NotifyToPrimaryPhone = val?.NotifyToPrimaryPhone ?? '';
  this.form.notify.NotifyToSecondaryPhone = val?.NotifyToSecondaryPhone ?? '';
  this.form.notify.NotifyToPrimaryEmail = val?.NotifyToPrimaryEmail ?? '';
  this.form.notify.NotifyToSecondaryEmail = val?.NotifyToSecondaryEmail ?? '';

  //Certificate of Entry
  this.certificateEntryList = val?.DocumentYardJson ?? [];

  //document
  this.documentItemsDB = val.DocumentJson && val.DocumentJson.length != 0 ? val.DocumentJson : [];
  this.documentItems = val.DocumentJson && val.DocumentJson.length != 0 ? val.DocumentJson : [];

  //DescriptionOfGoods
  this.form.DescriptionOfGoods.DescriptionOfGoods = val.DescriptionOfGoods;

  //validar check de consolidator
  if (this.form.consolidator.ConsolidatorId) {
    this.form.customBroke.consolidatorCheck = false;
  } else {
    this.form.customBroke.consolidatorCheck = true;
  }
 

  if(this.form.dataBasic.TpBlId === this.houseId.toUpperCase()){
    this.validCargoMaster = true;
  }else if (this.form.dataBasic.TpBlId === this.masterId.toUpperCase()){
    this.validCargoMaster = false;
    this.form.dataBasic.MasterBlNro = 'N/A';
  }


  this.$v.form.dataBasic.$touch();
  this.$v.form.PackagingList.$touch();
  this.$v.form.seller.$touch();
  this.$v.form.customBroke.$touch();
  this.$v.form.consolidator.$touch();
  this.$v.form.consignee.$touch();
  this.$v.form.notify.$touch();
  this.$v.form.DescriptionOfGoods.$touch();
}

function getDataMaster(val) {
  //dataBasic
  this.form.dataBasic.BlCargoMasterId = val.BlCargoId ?? '';
  this.form.dataBasic.MasterBlNro = val.BlNro ?? '';
  this.form.dataBasic.TpBlId = this.houseId;
  this.form.dataBasic.VesselId = val.VesselId ?? '';
  this.form.dataBasic.VisitIdOrItineraryId = val.VisitId ?? (val.ItineraryId ?? '');
  this.form.dataBasic.PortActivityId = val.PortActivityId ?? '';
  this.form.dataBasic.Pol = val.LoadPortId ?? '';
  this.form.dataBasic.Pod = val.DischargePortId ?? '';
  this.form.dataBasic.BookingNro = val.BookingNro ?? '';
  this.form.dataBasic.Procedence = val.Procedence ?? '';

  //seller
  this.form.seller.SellerName = val?.SellerName ?? '';
  this.form.seller.SellerAddress = val?.SellerAddress ?? '';
  this.form.seller.SellerPrimaryPhone = val?.SellerPrimaryPhone ?? '';
  this.form.seller.SellerSecondaryPhone = val?.SellerSecondaryPhone ?? '';
  this.form.seller.SellerPrimaryEmail = val?.SellerPrimaryEmail ?? '';
  this.form.seller.SellerSecondaryEmail = val?.SellerSecondaryEmail ?? '';

  //customBroke
  this.form.customBroke.CustomBrokerId = val?.CustomBrokerId ?? '';
  this.form.customBroke.CustomBrokerRif = val?.CustomBrokerRif ?? '';
  this.form.customBroke.CustomBrokerName =  val?.CustomBrokerName ?? '';
  this.form.customBroke.CustomBrokerAddress = val?.CustomBrokerAddress ?? '';
  this.form.customBroke.CustomBrokerPrimaryPhone = val?.CustomBrokerPrimaryPhone ?? '';
  this.form.customBroke.CustomBrokerSecondaryPhone = val?.CustomBrokerSecondaryPhone ?? '';
  this.form.customBroke.CustomBrokerPrimaryEmail = val?.CustomBrokerPrimaryEmail ?? '';
  this.form.customBroke.CustomBrokerSecondaryEmail = val?.CustomBrokerSecondaryEmail ?? '';
  
  //consolidator
  this.form.consolidator.ConsolidatorId = val?.ConsolidatorId ?? '';
  this.form.consolidator.ConsolidatorRif = val?.ConsolidatorRif ?? '';
  this.form.consolidator.ConsolidatorName = val?.ConsolidatorName ?? '';
  this.form.consolidator.ConsolidatorAddress = val?.ConsolidatorAddress ?? '';
  this.form.consolidator.ConsolidatorPrimaryPhone = val?.ConsolidatorPrimaryPhone ?? '';
  this.form.consolidator.ConsolidatorSecondaryPhone = val?.ConsolidatorSecondaryPhone ?? '';
  this.form.consolidator.ConsolidatorPrimaryEmail = val?.ConsolidatorPrimaryEmail ?? '';
  this.form.consolidator.ConsolidatorSecondaryEmail = val?.ConsolidatorSecondaryEmail ?? '';

  //consignee
  this.form.consignee.ConsigneeId = val?.ClientTpId ?? '';
  this.form.consignee.ConsigneeRif = val?.ClientRif ?? '';
  this.form.consignee.ConsigneeAddress = val?.ClientAddress ?? '';
  this.form.consignee.ConsigneeName = val?.ClientName ?? '';
  this.form.consignee.ConsigneePrimaryPhone = val?.ClientPrimaryPhone ?? '';
  this.form.consignee.ConsigneeSecondaryPhone = val?.ClientSecondaryPhone ?? '';
  this.form.consignee.ConsigneePrimaryEmail = val?.ClientPrimaryEmail ?? '';
  this.form.consignee.ConsigneeSecondaryEmail = val?.ClientSecondaryEmail ?? '';

  //validar check de consolidator
  if (this.form.consolidator.ConsolidatorId) {
    this.form.customBroke.consolidatorCheck = false;
  } else {
    this.form.customBroke.consolidatorCheck = true;
  }
 
  this.validCargoMaster = true;
  setTimeout(() => {
    this.$v.$reset();
  }, 20)
}

async function resetForm() {
  //validations
  this.form.customBroke.consolidatorCheck = false;
  this.validCargoMaster = false;

  //data array
  this.imdgItems = [];
  this.certificateEntryList = [];
  this.documentItems = [];
  this.documentItemsDB = [];
  this.headingJsonDB = [];
  this.CommodityList = [];
  
  //tab
  this.tabIndex = 0;
  
  //await this.Refresh();
  setTimeout(() => {
    this.$v.$reset();
  }, 20)
  this.$emit('close');
}

function submitImdg(item) {
  if (this.form.imdg.Id == 0) 
    this.imdgItems.push(item);
  else
  this.imdgItems = this.imdgItems.map((e) =>{
    if (e.IdX == this.form.imdg.Id) {
      return {
        ...e,
        ...item
      }
    }else {
      return e
    }
  });
}

function deleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      this.imdgItems = this.imdgItems.filter(e => e.IdX != item.IdX);
    }
  });
}

function formatNumber(item, decimal) {
  return  NumberFormater.formatNumber(item, decimal)
}

//-----------------------  Computed  ------------------------
function isExporter() {
  return typeof this.form.dataBasic.PortActivityId == 'string' && this.form.dataBasic.PortActivityId.toUpperCase() == process.env.VUE_APP_PORT_ACTIVITY_EXPORT_ID;
}

function isImporter() {
  return typeof this.form.dataBasic.PortActivityId == 'string' && this.form.dataBasic.PortActivityId.toUpperCase() == process.env.VUE_APP_PORT_ACTIVITY_ID_IMPORT;
}

function getConsigneeLabel() {
  if(this.isExporter)
    return this.$t('label.exporter');
  else if (this.isImporter)
    return this.$t('label.importer');
  else
    return this.$t('label.consignee');
}

function getClientLabel() {
  if(this.isExporter)
    return this.$t('label.Shopper');
  else if (this.isImporter)
    return this.$t('label.seller');
  else
    return this.$t('label.seller');
}

function PackagingList() {
  return this.form.PackagingList;
}

function SerializationList() {
  return this.form.SerializationList;
}

export default {
  name: 'modal-bl',
  components: {
    CustomTabs,
    CustomTab,
    PackagingTab,
    DataBasicTab,
    SerializationTab,
    ImdgTab,
    ClientTab,
    CustomsBrokerTab,
    ConsigneeTab,
    NotifyPartyTab,
    DiceContenerTab,
    CertificateEntryTab,
    DocumentationTab,
    BlStatusTab,
    Money,
  },
  props: { 
    modal: Boolean, 
    isEdit: Boolean, 
    Bl: Object, 
    BlMaster: Object, 
    items: Array, 
    title: String, 
    fgHouse: Boolean,
    fgInfo: Boolean,
  },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){ return BlValidations(this.form.customBroke.consolidatorCheck,this.validCargoMaster,this.form.dataBasic.FgClassification,this.form.Packaging.FgPackaging, this.form.Packaging.FgSerialOrigin, NumberFormater.setNum(this.form.Packaging.WeightOrigin))},
  directives: UpperCase,
  methods: {
    submitBody,
    getData,
    getDataMaster,
    resetForm,
    handleTab,
    deleteImdg,
    submitImdg,
    formatNumber,
  },
  computed: {
    isExporter,
    isImporter,
    getConsigneeLabel,
    getClientLabel,
    PackagingList,
    SerializationList,
  },
  watch: {
    modal: async function (val) {
      this.$v.$reset();
      this.modalActive = val;
      if(val){
        if (this.isEdit) {
          await this.getData(this.Bl);
        }else{
          if (!this.fgHouse) {
            this.form.dataBasic.TpBlId = this.masterId;
            this.form.dataBasic.MasterBlNro = 'N/A';
          }else {
            this.getDataMaster(this.BlMaster);
          }
        }
      }
    },
    PackagingList: function(List){
      let ImdgJson = [];
      List.map(e => {
        if (e.Status == 1) {
          e.ImdgJson.map(el => {
            if (el.Status ==1 && !ImdgJson.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)) {
              ImdgJson.push(el);
            }
          })
        }
      })
      this.form.SerializationList.map(e => {
        if (e.Status == 1) {
          e.ImdgJson.map(el => {
            if (el.Status ==1 && !ImdgJson.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)) {
              ImdgJson.push(el);
            }
          })
        }
      })
      this.imdgItems = [...ImdgJson];
    },
    SerializationList: function(List) {
      let ImdgJson = [];
      List.map(e => {
        if (e.Status == 1) {
          e.ImdgJson.map(el => {
            if (el.Status ==1 && !ImdgJson.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)) {
              ImdgJson.push(el);
            }
          })
        }
      })
      this.form.PackagingList.map(e => {
        if (e.Status == 1) {
          e.ImdgJson.map(el => {
            if (el.Status ==1 && !ImdgJson.some(ele => ele.ImdgClassId == el.ImdgClassId && ele.ImdgId == el.ImdgId && ele.PackagingGroupId == el.PackagingGroupId && ele.UnNumberId == el.UnNumberId)) {
              ImdgJson.push(el);
            }
          })
        }
      })
      this.imdgItems = [...ImdgJson];
    }
  },
};
</script>
<style scoped>
.border-title{
  background-color: transparent;
  color: #3c4c64;

}
</style>
<style lang="scss">
.text-table .custom-tab-content {
  background: #fff;
  border: 0;
  outline: none;
  border-radius: 5px;
  border-top-left-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>